import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout/layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { SEO as SearchEngineOptimization } from "../components/common/seo"
import { buildPageDescription } from "../lib/helper"
import AnimationCircleWhite from "../components/animation/animation-circle-white"
import AnimationCirclePink from "../components/animation/animation-circle-pink"
import AnimationElement1 from "../components/animation/animation-element-1"
import AnimationElement2 from "../components/animation/animation-element-2"

const NewsSingle = props => {
  const { pageContext } = props
  const { title, body, thumbnail = {} } = pageContext
  const image = getImage(thumbnail)
  return (
    <Layout>
      <ContentWrapper
        className="p-4 px-2 px-md-5 rounded-4 position-relative"
        style={{ zIndex: "2" }}
      >
        <div className="content-wrapper container ">
          <div>
            {image && (
              <div>
                <GatsbyImage
                  alt={title || ""}
                  image={image}
                  imgClassName="preview"
                  loading="eager"
                  className="rounded-4"
                />
              </div>
            )}
          </div>
          <div className="pt-4">
            <h1>{title}</h1>
            <div
              dangerouslySetInnerHTML={{ __html: body?.value }}
              className="node-body"
            />
          </div>
        </div>
      </ContentWrapper>
      <p className="py-5" style={{ position: "relative", zIndex: 1 }}>
        <Link to="/news" className="read-more white">
          See all news >>
        </Link>
      </p>
      <AnimationCircleWhite
        style={{ top: "10%" }}
        className="animation animation-circle-white-1"
      />
      <AnimationCirclePink
        style={{ top: "80%" }}
        className="animation animation-circle-pink-1"
      />
      <AnimationElement1
        className="animation"
        style={{
          position: "absolute",
          top: "30%",
          left: "-150px",
          width: "60px",
        }}
      />
      <AnimationElement2
        className="animation"
        style={{
          top: "50%",
          position: "absolute",
          right: "-150px",
          transform: "scaleX(-1)",
          width: "120px",
        }}
      />
    </Layout>
  )
}

const ContentWrapper = styled.div`
  background-color: #ffffff;
  color: #000000;
`

export default NewsSingle

export const Head = ({ pageContext }) => {
  const { title, body, entityUrl, imageUrl } = pageContext
  const description = buildPageDescription(body?.value)
  return (
    <SearchEngineOptimization
      title={title}
      description={description}
      pathname={entityUrl}
      image={imageUrl}
    />
  )
}
